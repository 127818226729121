import React from "react"
import BackgroundImage from "gatsby-background-image"

import styles from "./content.module.css"

export default function Content({ children, classVariant, background }) {
  return (
    <>
      {background ? (
        <BackgroundImage
          className={`${styles.content} ${
            classVariant ? styles[classVariant] : ""
          }`}
          Tag="div"
          fluid={background}
          backgroundColor={`#fff`}
        >
          {children}
        </BackgroundImage>
      ) : (
        <div
          className={`${styles.content} ${
            classVariant ? styles[classVariant] : ""
          }`}
        >
          {children}
        </div>
      )}
    </>
  )
}
